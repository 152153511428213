import React, { useCallback, useEffect, useState } from 'react';
import API, { standardAPI, graphqlOperation } from 'Common/limitless-appsync';
import useStateWithPromise from './useStateWithPromise';

const onUpdateSocketId = /* GraphQL */ `
  subscription OnUpdateSocketId($id: ID!) {
    onUpdateSocketId(id: $id) {
      id
    }
  }
`;

const createSocket = /* GraphQL */ `
  mutation CreateSocket(
    $input: CreateSocketInput!
    $condition: ModelSocketConditionInput
  ) {
    createSocket(input: $input, condition: $condition) {
      id
    }
  }
`;

const getSocket = /* GraphQL */ `
  query GetSocket($id: ID!) {
    getSocket(id: $id) {
      id
      message
    }
  }
`;

const updateSocket = /* GraphQL */ `
  mutation UpdateSocket(
    $input: UpdateSocketInput!
    $condition: ModelSocketConditionInput
  ) {
    updateSocket(input: $input, condition: $condition) {
      id
    }
  }
`;

const callZoom = async ({ relativeUrl, method, body, access_token }) => {
  return await standardAPI[method]('dialogozoomapi', relativeUrl, {
    body,
    headers: {
      Authorization: access_token,
    },
  });
};
const refreshToken = async ({ refresh_token }) => {
  const refreshedToken = await standardAPI.post(
    'dialogozoomapi',
    `/zoom/token`,
    {
      body: {
        grant_type: 'refresh_token',
        refresh_token: refresh_token,
      },
    },
  );
  return refreshedToken;
};

const useZoom = ({ user }) => {
  const [zoomData, setZoomData] = useStateWithPromise();

  useEffect(() => {
    if (!user) return;

    const updateZoomToken = async () => {
      let res = await API.graphql(
        graphqlOperation(getSocket, {
          id: user.id,
        }),
      );
      let message = res.data.getSocket?.message;
      if (!res.data.getSocket) {
        res = await API.graphql(
          graphqlOperation(createSocket, {
            input: {
              id: user.id,
            },
          }),
        );
        message = res.data.createSocket.message;
      }
      const zoomTokenData = message ? JSON.parse(message) : undefined;
      await setZoomData(zoomTokenData);
    };

    const loadOrCreateAndSubscribeToZoomSocket = async (subscription) => {
      if (!subscription) {
        await updateZoomToken();
        subscription = standardAPI
          .graphql(
            graphqlOperation(onUpdateSocketId, {
              id: user.id,
            }),
          )
          .subscribe({
            next: (props) => {
              updateZoomToken();
            },
            error: (e) => {
              console.error('on-update-socket-id', e);
            },
          });
      }
      return subscription;
    };

    let subscription;
    loadOrCreateAndSubscribeToZoomSocket(subscription).then(
      (sub) => (subscription = sub),
    );

    return () => subscription?.unsubscribe();
  }, [user?.id]);

  const callZoomWithRefreshTokens = useCallback(
    async ({ relativeUrl, method, body }) => {
      const response = await callZoom({
        relativeUrl,
        method,
        body,
        access_token: zoomData?.access_token,
      });
      if (response?.code === 124) {
        const refreshedTokenRes = await refreshToken(zoomData);
        if (refreshedTokenRes?.error) {
          await signOut();
          return null;
        } else {
          await setZoomData(refreshedTokenRes);

          return await callZoom({
            relativeUrl,
            method,
            body,
            access_token: refreshedTokenRes?.access_token,
          });
        }
      } else {
        return response;
      }
    },
    [zoomData],
  );

  const signIn = async () => {
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: 'XycheQjCRlCI2gTSgo7s9Q',
      redirect_uri: `${window.location.origin}/oauth/${user.id}`,
    }).toString();
    window.open(`https://zoom.us/oauth/authorize?${params}`, '_blank');
  };

  const signOut = async () => {
    await API.graphql(
      graphqlOperation(updateSocket, {
        input: {
          id: user.id,
          message: null,
        },
      }),
    );
    await setZoomData(null);
  };

  return {
    callZoom: callZoomWithRefreshTokens,
    zoomData,
    setZoomData,
    signIn,
    signOut,
  };
};

export default useZoom;
