import React, { useContext } from 'react';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green, red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

import { SnackbarContext } from 'Common/AppContext';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: red[700],
  },
  info: {
    backgroundColor: `#3569b1`,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default (props) => {
  const classes = useStyles1();
  const { snackbarProps, setSnackbarProps } = useContext(SnackbarContext);

  if (!snackbarProps) return null;

  const {
    className = '',
    open = false,
    message = '',
    onClose = () => setSnackbarProps({ open: false }),
    variant = 'info',
    autoHideDuration = 6000,
    ...other
  } = snackbarProps;
  const Icon = variantIcon[variant];

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}>
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </Snackbar>
  );
};

// import React from 'react';
// import clsx from 'clsx';
// import { makeStyles } from '@material-ui/core/styles';
// import MuiSnackbar from '@material-ui/core/Snackbar';
// import Slide from '@material-ui/core/Slide';
// import CloseIcon from '@material-ui/icons/Close';
// import IconButton from '@material-ui/core/IconButton';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import ErrorIcon from '@material-ui/icons/Error';
// import InfoIcon from '@material-ui/icons/Info';
// import WarningIcon from '@material-ui/icons/Warning';

// const variantIcon = {
//   success: CheckCircleIcon,
//   warning: WarningIcon,
//   error: ErrorIcon,
//   info: InfoIcon
// };

// const useStyles = makeStyles(theme => ({
//   content: {
//     backgroundColor: theme.palette.secondary.light,
//     color: theme.palette.text.primary,
//     flexWrap: 'inherit',
//     [theme.breakpoints.up('md')]: {
//       borderTopLeftRadius: 0,
//       borderTopRightRadius: 0,
//       borderBottomRightRadius: 4,
//       borderBottomLeftRadius: 4
//     }
//   },
//   contentMessage: {
//     fontSize: 16,
//     display: 'flex',
//     alignItems: 'center'
//   },
//   contentAction: {
//     paddingLeft: theme.spacing(2)
//   },
//   success: {
//     backgroundColor: green[600]
//   },
//   error: {
//     backgroundColor: theme.palette.error.dark
//   },
//   info: {
//     backgroundColor: theme.palette.primary.main
//   },
//   warning: {
//     backgroundColor: amber[700]
//   },
//   icon: {
//     fontSize: 20
//   },
//   iconVariant: {
//     opacity: 0.9,
//     marginRight: theme.spacing(1)
//   },
//   close: {
//     padding: theme.spacing(1)
//   }
// }));

// const Transition = props => <Slide {...props} direction="down" />;

// const Snackbar = props => {
//   const classes = useStyles();
//   const {
//     open,
//     onClose,
//     message,
//     autoHideDuration = 6000,
//     variant = 'info',
//     ...other
//   } = props;

//   const Icon = variantIcon[variant];

//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }

//     onClose(false);
//   };

//   return (
//     <MuiSnackbar
//       className={clsx(classes[variant], className)}
//       open={open}
//       onClose={handleClose}
//       variant={variant}
//       anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//       autoHideDuration={autoHideDuration}
//       transition={Transition}
//       ContentProps={{
//         'aria-describedby': 'snackbar',
//         classes: {
//           root: classes.content,
//           message: classes.contentMessage,
//           action: classes.contentAction
//         }
//       }}
//       message={
//         <React.Fragment>
//           <InfoIcon className={classes.info} />
//           <span id="snackbar">{message}</span>
//         </React.Fragment>
//       }
//       action={[
//         <IconButton
//           key="close"
//           aria-label="Close"
//           color="inherit"
//           className={classes.close}
//           onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       ]}
//       {...other}
//     />
//   );
// };

// export default Snackbar;
