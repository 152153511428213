/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "adminApi",
            "endpoint": "https://vrjnp4lcxd.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "dialogoemailservice",
            "endpoint": "https://1kt0te38xf.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "dialogolambda",
            "endpoint": "https://9xx43wnupk.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "dialogostepstripe",
            "endpoint": "https://6e08yb4e14.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "dialogostripeapi",
            "endpoint": "https://ul5n3gijj8.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "dialogozoomapi",
            "endpoint": "https://38ykeujkxe.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://jgm3s776xvga3a7lcdv2k26rji.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:0560bf09-da03-4e5b-b853-c90b01b27e7b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_PZTqBQAe7",
    "aws_user_pools_web_client_id": "5d0bak1j57pb9a88fvm1io1i48",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "dialogofb11a7a0cc724e9a85073cfb12d1367a122108-master",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
