import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import API, { graphqlOperation } from 'Common/limitless-appsync';
import { UserContext } from 'Common/AppContext';
import { LoaderContext } from 'Common/AppContext';
import { standardAPI } from 'Common/limitless-appsync';

// ZoomMtg. .setZoomJSLib("https://source.zoom.us/1.10.6/lib", "/av");
// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareJssdk();

const updateSocket = /* GraphQL */ `
  mutation UpdateSocket(
    $input: UpdateSocketInput!
    $condition: ModelSocketConditionInput
  ) {
    updateSocket(input: $input, condition: $condition) {
      id
    }
  }
`;

const ZoomOauth = () => {
  const [zoomData, setZoomData] = useState();
  const { setUser } = useContext(UserContext);
  const setIsLoader = useContext(LoaderContext);

  const history = useHistory();
  const { socketId } = useParams();

  const code = React.useMemo(
    () => new URLSearchParams(history.location.search)?.get('code'),
    [history.location.search],
  );

  useEffect(() => {
    const connect = async () => {
      try {
        const nextZoomData = await standardAPI.post(
          'dialogozoomapi',
          `/zoom/token`,
          {
            body: {
              grant_type: 'authorization_code',
              code: code,
              redirect_uri: `${window.location.origin}/oauth/${socketId}`,
            },
          },
        );
        setZoomData(nextZoomData);
      } catch (e) {
        console.error('error', e);
      }
    };
    if (code) {
      connect();
    }
  }, [code]);

  useEffect(() => {
    const socketBackToClient = async () => {
      setIsLoader(true);
      await API.graphql(
        graphqlOperation(updateSocket, {
          input: {
            id: socketId,
            message: JSON.stringify(zoomData),
          },
        }),
      );
      window.close();
      setIsLoader(false);
    };
    if (zoomData) {
      socketBackToClient();
    }
  }, [zoomData]);

  return <div></div>;
};

export default ZoomOauth;
