import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Error from 'Root/pages/Home/components/Error';

const useStyles = makeStyles((theme) => ({
  dedicatedPage: {
    background:
      theme.palette.type === 'dark'
        ? theme.palette.background.default
        : theme.palette.background.paper,
  },
}));

function ErrorPage(props) {
  const classes = useStyles();
  const { onToggleDark, onToggleDir } = props;
  const { errorCode, stars, t } = props;

  if (errorCode) {
    return (
      <Fragment>
        <div className={classes.dedicatedPage}>
          <Error
            errorCode={errorCode}
            text={'La pagina richiesta non è stata trovata'}
          />
        </div>
      </Fragment>
    );
  }

  return (
    <div className={classes.dedicatedPage}>
      {t('description')}
      Next stars:&nbsp;
      {stars}
    </div>
  );
}

ErrorPage.propTypes = {
  errorCode: PropTypes.string,
  stars: PropTypes.number,
};

ErrorPage.defaultProps = {
  errorCode: '400',
  stars: 0,
};

export default ErrorPage;
