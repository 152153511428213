import React from 'react';
import Spinner from 'react-spinkit';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@mui/material';

// https://kyleamathews.github.io/react-spinkit/

const useStyles = makeStyles((theme) => ({
  root: {
    top: 0,
    backgroundColor: theme.palette.primary.dark,
    opacity: 0.9,
    position: 'fixed',
    zIndex: 9999,
    width: '100%',
    height: '100vh',
  },
  container: {
    position: 'absolute',
    top: '50vh',
    left: '50%',
    transform: 'translate(-50%)',
  },
  loader: {
    zoom: 2,
    color: theme.palette.secondary.main,
  },
}));

function isNumber(value) {
  return typeof value === 'number';
}

const Loader = ({ isLoader }) => {
  const classes = useStyles();

  if (!isNumber(isLoader) && !isLoader) return null;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Spinner name="ball-spin-fade-loader" className={classes.loader} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Typography
            variant="body1"
            component="div"
            style={{ color: 'white' }}>
            {isNumber(isLoader) ? `${isLoader.toFixed(0)}%` : ''}
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default Loader;
