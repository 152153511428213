import React from 'react';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Paper, Typography, Link, Button } from '@material-ui/core';

import CookieImage from 'Assets/images/undraw_cookie_love_ulvn.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 320,
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: 'none',
  },
  media: {
    padding: theme.spacing(1, 2),
    height: 180,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto',
    },
  },
  content: {
    padding: theme.spacing(1, 2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2, 2, 1),
  },
}));

export default (props) => {
  const classes = useStyles();
  const { open, onClose, className, ...rest } = props;

  const handleClose = () => {
    Cookies.set('dialogo_consent', new Date().toISOString());
    onClose && onClose();
  };

  return (
    <Modal open={open}>
      <Paper {...rest} className={clsx(classes.root, className)}>
        <div className={classes.media}>
          <img alt="Cookies" src={CookieImage} />
        </div>
        <div className={classes.content}>
          <Typography variant="body1">
            {`Utilizziamo i Cookies per assicurarti il miglior utilizzo possibile
            del sito. Leggi la nostra policy sulla privacy.`}
            <Link
              className={classes.link}
              component="a"
              href="/privacy"
              target="_blank"
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </div>
        <div className={classes.actions}>
          <Button
            className={classes.agreeButton}
            color="primary"
            onClick={handleClose}
            variant="contained"
          >
            OK
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};
