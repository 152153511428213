import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ErrorPage from 'Common/Views/NotFound/_error';
import ZoomOauth from './pages/ZoomOauth';

const Home = lazy(() => import('./pages/Home'));
const PreSignup = lazy(() => import('./pages/PreSignup'));
const Contact = lazy(() => import('./pages/ContactPage'));
const Documentation = lazy(() => import('./pages/DocumentationPage'));
const About = lazy(() => import('./pages/AboutPage'));
const Professional = lazy(() => import('Professional'));
const Admin = lazy(() => import('Admin'));
const Client = lazy(() => import('Client'));
const Login = lazy(() => import('./pages/Login'));
const SignUp = lazy(() => import('./pages/SignUp'));
const TermsClientPage = lazy(() => import('./pages/TermsClientPage'));
const TermsProfessionalPage = lazy(
  () => import('./pages/TermsProfessionalPage'),
);
const Appointment = lazy(() => import('Client/Pages/Appointment'));
const PrivacyPage = lazy(() => import('./pages/PrivacyPage'));
const Header = lazy(() => import('./pages/Home/components/Header'));
const Footer = lazy(() => import('./pages/Home/components/Footer'));

const sectionMargin = (margin) => margin * 20;
const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: `calc(100vh - ${294 + 81}px)`, // 100% - header and footer
    // minHeight: 'calc(100vh - 247px)',
  },
  spaceTopShort: {
    marginTop: sectionMargin(theme.spacing() / 2),
  },
}));

const HeaderContainer = ({ children, fixed, keyNum }) => (
  <>
    <Header fixed={fixed} key={keyNum} />
    {fixed ? <div style={{ marginTop: 63 }}>{children}</div> : children}
  </>
);

const Authenticated = ({ children, user }) => {
  return user ? children : <Redirect to="/" />;
};

const generateRedirectUrl = (user) => {
  if (
    user.type === 'client' &&
    (!user.email || !user.givenName || !user.familyName || !user.phone)
  ) {
    return `/${user.type}/setup`;
  } else if (user.type === 'client' && !user.surveyResponse) {
    return `/${user.type}/setup?step=1`;
  } else {
    return `/${user.type}/dashboard`;
  }
};

export default ({ user }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Switch>
          <Route path="/oauth/:socketId">
            <ZoomOauth />
          </Route>
          {user && !window.location.pathname.includes('oauth') ? (
            <Redirect exact from={'/'} to={`/${user.type}`} />
          ) : (
            <Route exact path="/">
              <HeaderContainer keyNum="0">
                <Home />
              </HeaderContainer>
            </Route>
          )}
          {user ? (
            <Redirect
              exact
              from={'/signUp/:group'}
              to={generateRedirectUrl(user)}
            />
          ) : (
            <Route exact path="/signUp/:group">
              <HeaderContainer keyNum={'1'} fixed={null}>
                <SignUp />
              </HeaderContainer>
            </Route>
          )}
          {user ? (
            <Redirect exact from={'/signIn'} to={generateRedirectUrl(user)} />
          ) : (
            <Route exact path="/signIn">
              <HeaderContainer keyNum={'2'} fixed={null}>
                <Login />
              </HeaderContainer>
            </Route>
          )}
          <Route exact path="/preSignUp">
            <HeaderContainer keyNum={'3'} fixed={null}>
              <PreSignup />
            </HeaderContainer>
          </Route>
          <Route exact path="/terms-client">
            <HeaderContainer keyNum={'7'} fixed>
              <TermsClientPage />
            </HeaderContainer>
          </Route>
          <Route exact path="/terms-professional">
            <HeaderContainer keyNum={'4'} fixed>
              <TermsProfessionalPage />
            </HeaderContainer>
          </Route>
          <Route exact path="/privacy">
            <HeaderContainer keyNum={'5'} fixed>
              <PrivacyPage />
            </HeaderContainer>
          </Route>
          <Route exact path="/about">
            <HeaderContainer keyNum={'6'} fixed>
              <About />
            </HeaderContainer>
          </Route>
          <Route exact path="/contact">
            <HeaderContainer keyNum={'9'} fixed>
              <Contact />
            </HeaderContainer>
          </Route>
          <Route exact path="/documentation">
            <HeaderContainer keyNum={'10'} fixed>
              <Documentation />
            </HeaderContainer>
          </Route>
          <Authenticated user={user}>
            <Route path="/professional" component={Professional} />
            <Route path="/client" component={Client} />
            <Route path="/admin" component={Admin} />
            <Route exact path={`/appointments/:id`} component={Appointment} />
          </Authenticated>
          <Route path="*">
            <HeaderContainer keyNum={'8'} fixed>
              <ErrorPage />
            </HeaderContainer>
          </Route>
        </Switch>
      </div>
      {!window.location.href.includes('messenger') ? (
        <div>
          <section>
            <Footer />
          </section>
        </div>
      ) : null}
    </>
  );
};
