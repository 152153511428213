import React, { Suspense, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Langs from '../compiled-lang';

import LogEvent, { EventTypes } from 'Common/funcs/LogEvent';
import { create } from 'jss';

// COMPONENTS
// import GoogleAnalytics from './components/GoogleAnalytics';
// import ScrollToTop from './components/ScrollToTop';
import CookiesNotification from './components/CookiesNotification';
import InstallPWA from './components/InstallPWA';
import Loader from 'Common/Components/Loader';
import Snackbar from 'Common/Components/Snackbar';

// ROUTES
import Routes from './routes';

// AWS
import {
  UserContext,
  LoaderContext,
  SnackbarContext,
  ZoomContext,
  LocaleContext,
  NotificationsContext,
} from 'Common/AppContext';
import { translations } from '@aws-amplify/ui';
import { AmplifyProvider } from '@aws-amplify/ui-react';

import limitlessAppsyncAPI, {
  graphqlOperation,
} from 'Common/limitless-appsync';
import { PubSub } from '@aws-amplify/pubsub';
import {
  Storage,
  Auth,
  API,
  Amplify,
  I18n,
  Hub,
  // graphqlOperation,
} from 'aws-amplify';

import awsconfig from 'aws-exports';
import appTheme from 'theme/appTheme';
import jssRTL from 'jss-rtl';

// LOGGING
// import 'Utils/ReactotronConfig';

// STYLE
import 'react-perfect-scrollbar/dist/css/styles.css';

/* import css vendors */
import '../vendors/animate-slider.css';
import '../vendors/hamburger-menu.css';
import 'animate.css/animate.css';
import '../vendors/top-loading-bar.css';
import '../vendors/animate-extends.css';
import '../vendors/page-transition.css';
import '../vendors/slick/slick.css';
import '../vendors/slick/slick-theme.css';
import { createTheme } from '@material-ui/core';
import useZoom from 'Common/Hooks/useZoom';
import useNotifications from 'Common/Hooks/useNotifications';
// import './assets/scss/index.css';

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

Amplify.configure(awsconfig);
API.configure(awsconfig);
Auth.configure(awsconfig);
PubSub.configure(awsconfig);
Storage.configure(awsconfig);

const dict = {
  ...translations,
  it: {
    ...translations.it,
    'Create a new account': 'Crea un nuovo account',
    'Sign in to your account': 'Accedi',
    'Enter your username': 'Username',
    'Enter your password': 'Password',
    'Enter your email': 'Email',
    Email: 'Email',
    Password: 'Password',
    'We Sent A Code': 'Abbiamo Inviato il codice',
    'Reset your password': 'Recupera la password',
    'Confirm Password': 'Ripeti Password',
    'Creating Account': 'In corso',
    'Enter your code': 'Inserisci il codice',
    'Lost your code?': 'Perso il codice?',
    'Send code': 'Invia',
    'Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.':
      'Il tuo codice sta arrivando. Per accedere, inserisci il codice che ti abbiamo inviato. Potrebbe impiegare un minuto per arrivare.',
    'Reset password': 'Recupera password',
    'Lost your code? ': 'Non hai ricevuto il codice? ',
    'We Emailed You': 'Ti abbiamo inviato una mail',
    'Confirmation Code': 'Codice di conferma',
    Confirming: 'In corso',
    Confirm: 'Conferma',
    'Resend Code': 'Invia di nuovo',
    'Forget your password? ': 'Hai dimenticato la password? ',
    Name: 'Nome',
    'Sign in': 'Accedi',
    'Signing in': 'In corso',
    'Create Account': 'Crea account',
    'Pick a File': 'Carica nuovo CV in PDF',
    'Your code is on the way. To log in, enter the code we emailed to':
      "Il codice è in arrivo. Per effettuare l'accesso, immetti il codice che ti abbiamo inviato via e-mail",
    'It may take a minute to arrive':
      "L'arrivo potrebbe richiedere qualche minuto",
  },
};
I18n.setLanguage('it');
I18n.putVocabularies(dict);

const createLog = /* GraphQL */ `
  mutation CreateLog($input: CreateLogInput!) {
    createLog(input: $input) {
      id
      event
      data
      createdAt
    }
  }
`;

const getUserQuery = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      termsConsentDate
      privacyConsentDate
      givenName
      familyName
      displayName
      searchName
      surveyResponse
      company
      image {
        bucket
        region
        key
      }
      type
      isActive
      isAccountDeleted
      deletionDate
      about
      phone
      fiscalCode
      address
      stripeId
      proRegisterNumber
      proRegisterRegion
      vatNumber
      activeSinceYear
      sex
      tags
      videoUrl
      resume {
        bucket
        region
        key
      }
      resumeName
      resumeSize
      canChat
      priceChat
      canVideo
      priceVideo
      ordersWClient {
        items {
          id
          orderStatus
          type
          remainingNAppointments
          originalNAppointments
        }
      }
      professional {
        id
      }
    }
  }
`;

const createUserMutation = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      termsConsentDate
      privacyConsentDate
      givenName
      familyName
      displayName
      searchName
      about
      phone
      fiscalCode
      address
      surveyResponse
      company
      type
      isActive
      isAccountDeleted
      deletionDate
      stripeId
      proRegisterNumber
      proRegisterRegion
      vatNumber
      activeSinceYear
      sex
      tags
      videoUrl
      resumeName
      resumeSize
      canChat
      priceChat
      canVideo
      priceVideo
      ordersWClient {
        items {
          id
          orderStatus
          type
          remainingNAppointments
          originalNAppointments
        }
      }
      professional {
        id
      }
    }
  }
`;

const history = createBrowserHistory();

const Root = () => {
  const [locale, setLocale] = useState('it');
  const [openCookies, setOpenCookies] = useState(false);
  const [user, setUser] = useState();
  const { zoomData, setZoomData, callZoom, signIn, signOut } = useZoom({
    user,
  });
  const {
    notifications,
    setNotificationsRead,
    setSingleNotificationRead,
    setNotifications,
  } = useNotifications({
    user,
  });
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState();
  const [theme] = useState({ ...appTheme('oceanBlue', 'light') });

  useEffect(() => {
    I18n.setLanguage(locale);
  }, [locale]);

  useEffect(() => {
    const getUserData = async () => {
      try {
        setIsLoader(true);
        const user = await Auth.currentAuthenticatedUser();
        const { data } = await limitlessAppsyncAPI.graphql(
          graphqlOperation(getUserQuery, { id: user.username }),
        );
        if (data && !data.getUser) {
          registerNewUser(user.attributes.sub, user.attributes);
        }
        setUser(data?.getUser);
        setIsLoader(false);
        setIsUserLoaded(true);
      } catch (error) {
        console.error('error getUserData', error);
        setIsLoader(false);
        setIsUserLoaded(true);
      }
    };

    const registerNewUser = async (sub, attributes) => {
      const getUserInput = {
        id: sub,
      };

      const { data } = await limitlessAppsyncAPI.graphql(
        graphqlOperation(getUserQuery, getUserInput),
      );

      // if we can't get a user (meaning the user hasn't been registered before), then we execute registerUser
      if (!data.getUser) {
        try {
          const input = {
            id: getUserInput.id,
            email: attributes.email,
            givenName: attributes.given_name,
            familyName: attributes.family_name,
            searchName: attributes.family_name.toLowerCase(),
            displayName: `${attributes.given_name} ${attributes.family_name}`,
            type: attributes['custom:pro_register_number']
              ? 'professional'
              : 'client',
            // isFirstAccessCompleted: attributes['custom:pro_register_number']
            //   ? true
            //   : false,
            proRegisterNumber:
              attributes['custom:pro_register_number'] &&
              attributes['custom:pro_register_number'],
            proRegisterRegion:
              attributes['custom:pro_register_region'] &&
              attributes['custom:pro_register_region'],
            isActive: true,
            canChat: true,
            canVideo: true,
          };
          const { data } = await limitlessAppsyncAPI.graphql(
            graphqlOperation(createUserMutation, { input }),
          );
          setUser(data.createUser);

          // const logInput = {
          //   logUserId: data.createUser.id,
          //   event: 'REGISTRATION',
          // };
          // await API.graphql(graphqlOperation(createLog, { input: logInput }));
          await LogEvent(EventTypes.SIGN_UP, data.createUser);

          // data.createUser.type === 'client'
          //   ? history.push(`/${data.createUser.type}/setup`)
          //   : history.push(`/${data.createUser.type}/dashboard`);
        } catch (err) {
          console.error('Error registering new user', err);
          setIsLoader(false);
        }
      } else {
        setUser(data.getUser);
        // const logInput = {
        //   logUserId: data.getUser.id,
        //   event: 'LOGIN',
        // };
        // await API.graphql(graphqlOperation(createLog, { input: logInput }));
        await LogEvent(EventTypes.SIGN_IN, data.getUser);
        if (
          data.getUser.type === 'client' &&
          (!data.getUser.email ||
            !data.getUser.givenName ||
            !data.getUser.familyName ||
            !data.getUser.phone)
        ) {
          history.push(`/${data.getUser.type}/setup`);
        } else if (
          data.getUser.type === 'client' &&
          (!data.getUser.surveyResponse ||
            data.getUser.ordersWClient.items.some(
              (order) => order.orderStatus === 'pending',
            ))
        ) {
          history.push(`/${data.getUser.type}/setup?step=1`);
        } else {
          history.push(`/${data.getUser.type}/dashboard`);
        }
      }
    };

    const onSignupFaliure = ({ code, message }) => {
      switch (code) {
        case 'UsernameExistsException':
          return `Utente gia' registrato.`;
        case 'UserNotConfirmedException':
          return `L'utente non ha usato il codice di verifica.`;
        case 'PasswordResetRequiredException':
          return 'Password resettata.';
        case 'NotAuthorizedException':
          return `Password sbagliata.`;
        case 'UserNotFoundException':
          return `Utente non registrato. (controlla gli spazi)`;
        default:
          return message;
      }
    };

    const onHubCapsule = async (capsule) => {
      setIsLoader(true);
      const { event, data } = capsule.payload;
      switch (event) {
        case 'signIn':
          console.log('signed in');
          await getUserData();
          await registerNewUser(
            data.signInUserSession.idToken.payload.sub,
            data.attributes,
          );
          break;
        case 'signUp':
          console.log('signed up');
          setIsLoader(false);
          break;
        case 'signOut':
          console.log('signed out');
          if (data) {
            const type = data.attributes['custom:pro_register_number']
              ? 'professional'
              : 'client';
            await LogEvent(EventTypes.SIGN_OUT, { id: data.username, type });
          }
          setUser(null);
          setIsLoader(false);
          break;
        case 'forgotPassword':
          console.log(data.message);
          setSnackbarProps({
            open: true,
            variant: 'info',
            onClose: () => setSnackbarProps({ open: false }),
            message: `Codice di verifica inviato a ${data.username}`,
          });
          setIsLoader(false);
          break;
        case 'forgotPasswordSubmit':
          setSnackbarProps({
            open: true,
            variant: 'info',
            onClose: () => setSnackbarProps({ open: false }),
            message: `Password modificata con successo, ora puoi effettuare il login.`,
          });
          setIsLoader(false);
          break;
        case 'signUp_failure':
        case 'signIn_failure': {
          console.log(data.message);
          const errorMessage = onSignupFaliure(data);
          setSnackbarProps({
            open: true,
            variant: 'error',
            onClose: () => setSnackbarProps({ open: false }),
            message: errorMessage,
          });
          setIsLoader(false);
          break;
        }
        default:
          setIsLoader(false);
      }
    };

    getUserData();
    Hub.listen('auth', onHubCapsule);
  }, []);

  useEffect(() => {
    const consent = Cookies.get('dialogo_consent');
    if (!consent) setOpenCookies(true);
  }, []);

  const handleCookiesClose = () => setOpenCookies(false);

  const muiTheme = createTheme(theme);

  const jss = create({ plugins: [...jssPreset().plugins, jssRTL()] });

  return (
    <React.StrictMode>
      <IntlProvider locale={locale} messages={Langs[locale]} defaultLocale="it">
        <AmplifyProvider>
          <StylesProvider jss={jss}>
            <ThemeProvider theme={muiTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <LocaleContext.Provider value={{ locale, setLocale }}>
                    <UserContext.Provider value={{ user, setUser }}>
                      <ZoomContext.Provider
                        value={{
                          zoomData,
                          setZoomData,
                          callZoom,
                          signIn,
                          signOut,
                        }}>
                        <NotificationsContext.Provider
                          value={{
                            notifications,
                            setNotifications,
                            setNotificationsRead,
                            setSingleNotificationRead,
                          }}>
                          <LoaderContext.Provider value={setIsLoader}>
                            <SnackbarContext.Provider
                              value={{ snackbarProps, setSnackbarProps }}>
                              <CookiesNotification
                                onClose={handleCookiesClose}
                                open={openCookies}
                              />
                              <Router history={history}>
                                {/* {user ? <LoggedHeader /> : <Header date={new Date()} />} */}
                                <Suspense fallback={<></>}>
                                  {isUserLoaded ? (
                                    <Routes user={user} />
                                  ) : (
                                    <></>
                                  )}
                                </Suspense>
                                {/* <Footer /> */}
                              </Router>
                              <InstallPWA />
                              <Loader isLoader={isLoader} />{' '}
                              <Snackbar {...snackbarProps} />
                            </SnackbarContext.Provider>
                          </LoaderContext.Provider>
                        </NotificationsContext.Provider>
                      </ZoomContext.Provider>
                    </UserContext.Provider>
                  </LocaleContext.Provider>
                </LocalizationProvider>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </StylesProvider>
        </AmplifyProvider>
      </IntlProvider>
    </React.StrictMode>
  );
};

export default Root;
