import API, { graphqlOperation } from 'Common/limitless-appsync';

export const createLog = /* GraphQL */ `
  mutation CreateLog($input: CreateLogInput!) {
    createLog(input: $input) {
      id
    }
  }
`;

export const EventTypes = {
  SIGN_UP: 'signUp',
  SIGN_IN: 'signIn',
  SIGN_OUT: 'signOut',
  SIGNUP_FALIURE: 'signupFaliure',
  FORGOT_PASSWORD: 'forgotPassword',
};

export default async (event, user, data) => {
  let input = {
    event,
    userId: user.id,
    userType: user.type,
    logUserId: user.id,
  };
  if (data) input = { ...input, data };

  await API.graphql(graphqlOperation(createLog, { input })).catch((e) => {
    console.log(e);
  });
};
