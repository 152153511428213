import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    height: 100,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    backgroundColor: '#5FDA32',
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
}));

export default () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);

  const onInstall = (evt) => {
    evt.preventDefault();

    if (!promptInstall) {
      return;
    }

    promptInstall.prompt();

    promptInstall.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }

      setPromptInstall(null);
    });
  };

  const onDecline = () => {
    setSupportsPWA(false);
    setPromptInstall(null);
  };

  if (!supportsPWA) {
    return null;
  }

  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar style={{ paddingTop: 30 }}>
        <Typography variant="h5" color="inherit">
          Installa l'app Dialogo Online sul tuo dispositivo!
        </Typography>
        <Fab
          color="secondary"
          aria-label="install"
          // variant="extended"
          className={classes.fabButton}
          onClick={onInstall}
        >
          <CheckIcon color="inherit" style={{ fontSize: 50, color: '#fff' }} />
        </Fab>
        <div className={classes.grow} />
        <IconButton color="inherit" onClick={onDecline}>
          <ClearIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
