import massiveQueriesHelper from 'Common/massiveQueriesHelper';
import API, { standardAPI, graphqlOperation } from 'Common/limitless-appsync';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from 'Common/AppContext';
import { orderBy } from 'lodash';

const getUserNorificationsQuery = /* GraphQL */ `
  query getUserNotifications($userId: ID!) {
    notificationsByUserIdIsRead(
      userId: $userId
      isRead: { eq: "false" }
      sortDirection: ASC
    ) {
      items {
        id
        resourceId
        type
        userId
        createdAt
        isRead
        message
        link
      }
    }
  }
`;
const updateNotification = /* GraphQL */ `
  mutation updateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
    }
  }
`;

const updateNotificationMutationName = /* GraphQL */ `
    updateNotification(input: $input) {
        id
    }
`;

const onCreateNotificationUserId = /* GraphQL */ `
  subscription OnCreateNotificationUserId($id: ID!) {
    onCreateNotificationUserId(userId: $id) {
      id
      resourceId
      type
      userId
      createdAt
      isRead
      message
      link
    }
  }
`;
const sortNotifications = (notifications) =>
  orderBy(notifications, (n) => new Date(n.createdAt), ['desc']);

const useNotifications = ({ user }) => {
  const isInitialLoadRef = useRef(true);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    let subscription;
    const loadNotifications = async () => {
      // Load notifications from the server
      const response = await API.graphql(
        graphqlOperation(getUserNorificationsQuery, {
          userId: user.id,
        }),
      );
      const nextNotifications = response.data.notificationsByUserIdIsRead.items;
      setNotifications(sortNotifications(nextNotifications));
      isInitialLoadRef.current = false;
      subscription = standardAPI
        .graphql(
          graphqlOperation(onCreateNotificationUserId, {
            id: user.id,
          }),
        )
        .subscribe({
          next: ({ value }) => {
            const newNotification = value.data.onCreateNotificationUserId;
            setNotifications((currNotifications) =>
              sortNotifications([...currNotifications, newNotification]),
            );
          },
          error: (e) => {
            console.error('on-create-notification-userid', e);
          },
        });
    };

    if (!user && !isInitialLoadRef.current) {
      isInitialLoadRef.current = true;
      setNotifications([]);
    } else if (user && isInitialLoadRef.current) {
      loadNotifications();
    }
    return () => subscription?.unsubscribe?.();
  }, [user]);

  const setNotificationsRead = async (type) => {
    await massiveQueriesHelper.massiveMutation({
      query: updateNotificationMutationName,
      queryName: 'MarkNotificationsRead',
      items: nofifications
        .filter((n) => n.type === type)
        .map((item) => ({
          id: item.id,
          isRead: 'true',
        })),
      queryInputType: 'UpdateNotificationInput',
    });
    setNotifications((notifications) =>
      notifications.filter((n) => n.type !== type),
    );
  };

  const setSingleNotificationRead = async (id) => {
    const notification = notifications.find((n) => n.id === id);
    if (notification) {
      await API.graphql(
        graphqlOperation(updateNotification, {
          input: {
            id,
            isRead: 'true',
          },
        }),
      );
      setNotifications((notifications) =>
        notifications.filter((n) => n.id !== id),
      );
    }
  };

  return {
    notifications,
    setNotificationsRead,
    setSingleNotificationRead,
    setNotifications,
  };
};

export default useNotifications;
